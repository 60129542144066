.contactDetails {
  /* border-right: 1px solid #dad9d9; */
}
.messageContactField{
  width: 100%;
}
@media (max-width: 600px) {
  .contactDetails {
    /* border-right: none; */
    margin-top: 30px;
  }
  .messageContactField{
    width: 90%;
  }
}
