
@keyframes float {
	0% {
		/* box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6); */
		transform: translatey(0px);
	}
	50% {
		/* box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2); */
		transform: translatey(-40px);
        /* transform: translate3d(-40px,-30px,-20px); */
	}
	100% {
		/* box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6); */
		transform: translatey(0px);
	}
}

@keyframes float2 {
	0% {
		/* box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6); */
		transform: translatey(0px);
	}
	50% {
		/* box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2); */
		/* transform: translatey(-40px); */
        transform: translate3d(-40px,-30px,-20px);
	}
	100% {
		/* box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6); */
		transform: translatey(0px);
	}
}


.vegetable {
	/* width: "100%";
	height: "100%";
	box-sizing: border-box;
	border: "none"; */
	/* overflow: hidden; */
	/* box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6); */
	transform: translatey(0px);
	animation: float 30s ease-in-out infinite;

}



