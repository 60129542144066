@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.labelAccountTitle {
  line-height: 24px;
  color: #fff;
  font-size: 14px;
  letter-spacing: 1px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.darkBG {
  background: #141414;
  color: #fff;
  padding: 12px 15px;
  display: flex;
  align-items: center;
}
.accountTabStap li {
  border-bottom: 1px solid #cac7c7;
  position: relative;
  padding: 8px 15px;
  text-align: left;
  background-color: #f0f0f0;
}

.accountTabStap li.active {
  color: #fff;
  background: #128274 none repeat scroll 0 0;
}
.accountTabStap li button {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  color: #141414;
}
.titleParent {
  margin-bottom: 30px;
}
.titleParent > h2 {
  letter-spacing: 1px;
  font-weight: 500;
  background: #f0f0f0;
  padding: 8px 17px;
  font-size: 25px;
  color: #1b2839;
  font-family: "Roboto", sans-serif;
  text-align: left;
}
.profileAccount {
  margin-bottom: 30px;
  text-align: left;
}
.profileHeading > h3 {
  color: #1b2839;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 10px;
  text-transform: capitalize;
  font-size: 21px;
  line-height: 32px;
  text-align: left;
}
.profileHeading + p {
  line-height: 24px;
  color: #555555;
  font-size: 14px;
  letter-spacing: 1px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.profileHeading + p > button {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  color: #555555;
  font-size: 14px;
  letter-spacing: 1px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-align: left;
  line-height: 24px;
}
.profileHeading + p > button > a {
  color: #555555;
  font-size: 14px;
  letter-spacing: 1px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-align: left;
}

.tableAccount {
  border: 15px solid #f5f5f5;
  border-top: none;
  margin-bottom: 0;
  color: #212529;
  width: 100%;
  text-align: left;
}
.tableAccountTable {
  padding: 15px 25px;
  vertical-align: middle;
  background: #f5f5f5;
  border-bottom: none;
  color: #141414;
  font-size: 16px;
  letter-spacing: 1px;
  font-family: "Roboto", sans-serif;
}
.tableAccount > ul > li {
  padding: 2px 25px;
  vertical-align: middle;
  color: #555555;
  font-size: 14px;
  letter-spacing: 1px;
  font-family: "Roboto", sans-serif;
}
.accountTabStap {
  color: #555555;
  font-size: 14px;
  letter-spacing: 1px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  padding-left: 0px;
  text-align: left;
}
.m1Title {
  color: #1b2839;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 10px;
  text-transform: capitalize;
  font-size: 21px;
  line-height: 32px;
  text-align: left;
}
.inputFieldAccount {
  margin-bottom: 23px;
}
.accountiNputField {
  background: #fff;
  padding: 8px 15px;
  width: 100%;
  border: 1px solid #eeeeee;
  min-height: 40px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}
.accountInputLable {
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 1px;
  font-size: 14px;
  text-align: left;
  width: 100%;
  margin-top: 5px;
}
.accountCheckField {
  margin: 5px;
  min-height: 1px;
  background: pink;
  padding: 8px 15px;
  border: 1px solid #eeeeee;
}
.tableOrderList {
  border: 15px solid #f5f5f5;
  border-top: none;
  width: 100%;
  margin-bottom: 0;
  color: #212529;
}
.tableOrderListUl {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0;
}
.tableOrderListUlli1 {
  float: left;
  margin-right: 30px;
  text-align: left;
}
.tableOrderListUlli1 > span {
  letter-spacing: 1px;
  font-family: "Roboto", sans-serif;
  float: left;
  font-size: 14px;
  font-weight: 400;
  clear: both;
}

.tableOrderListUlli2 {
  float: left;
  margin-right: 30px;
  text-align: left;
}
.tableOrderListUlli2 > span {
  letter-spacing: 1px;
  font-family: "Roboto", sans-serif;
  float: left;
  font-size: 14px;
  font-weight: 400;
  clear: both;
}

.tableOrderListUlli3 {
  float: right;

  text-align: left;
}
.tableOrderListUlli3 > span {
  letter-spacing: 1px;
  font-family: "Roboto", sans-serif;
  float: left;
  font-size: 14px;
  font-weight: 400;
  clear: both;
}
.tableHeaderSpan {
  background: #f5f5f5;
  border-bottom: none;
  padding: 22px 20px;
  text-align: left;
}
.tableOrderDetailsRow {
  border-bottom: 1px solid #e1e1e1;
}
.tableOrderDetailsRow > td {
  padding: 20px 25px;
  vertical-align: middle;
  text-align: left;
}
.tableProductImg {
  min-width: 70px;
  overflow: hidden;
  position: relative;
}
.tableProductImg > img {
  width: 76px;
  height: 100px;
}
.productTitleTbale {
  /* min-width: 300px; */
}
.productTitleTbale > span {
  color: #555555;
  letter-spacing: 1px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}
.titlePriceOrderLsit > span {
  color: #128274;
  letter-spacing: 1px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}
.btnbtn {
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0;
}
.printBTN {
  padding: 13px 15px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  border: none;
  background-color: #128274;
  color: white;
  border-radius: 3px;
  margin-top: 25px;
}
.passwordInput {
  margin-bottom: 23px;
}
.passwordInput > label {
  display: inline-block;
  font-weight: 600;
  margin-bottom: 5px;
  max-width: 100%;
  color: #555555;
  font-size: 14px;
  letter-spacing: 1px;
  text-align: left;
  font-family: "Roboto", sans-serif;
  width: 100%;
}
.passwordInput > input {
  background: #fff;
  padding: 8px 15px;
  width: 100%;
  border: 1px solid #eeeeee;
  min-height: 40px;
}
.selectInput {
  padding: 8px 15px;
  width: 100%;
  border: 1px solid #eeeeee;
  min-height: 40px;
  appearance: none;
  background: url(https://aaryaweb.info/html/ezone/ezn004/images/select-icon.png)
    no-repeat scroll right 50% #fff !important;
  letter-spacing: 1px;
  font-size: 14px;
  text-align: left;
  min-height: 40px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  outline: none;
}
.outlineCheckStep {
  padding: 20px;
  border: 1px solid #e1e1e1;
}
.boaderCheckStep {
  padding: 20px;
  display: flex;
  width: 93%;
  background: #f5f5f5;
  flex-direction: column;
  text-align: left;
  letter-spacing: 1px;
  font-size: 14px;
  text-align: left;
  min-height: 40px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #555555;
}
.paymentTop {
  margin-bottom: 20px;
}
.paymentBox {
  /* border: 1px solid #ddd; */
  float: left;
  padding: 10px;
  position: relative;
  margin-left: 10px;
}
.payTop {
  background: #f5f5f5;
  position: absolute;
  top: -15px;
  left: 5px;
  padding: 0 10px;
}
.orderConfirmContaienr > h3 {
  color: #1b2839;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 10px;
  text-transform: capitalize;
  font-size: 21px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}
.orderConfirmParagraph {
  color: #555;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  padding: "20px 0";
}

.payImage{
  height: 40px;
}
@media (max-width: 600px) {
  .payImage{
    height: 25px;
  }
  .payTop {  
    top: -3px;    
  }
}
