input:-webkit-autofill {
    background:#f4f4f4;
    color:#585858;
  }
  input:autofill {
    background:#f4f4f4;
    color:#585858;
  }
  input:-internal-autofill-selected {
  
    background:#f4f4f4 !important;
    color:#585858 !important;
}
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px #f4f4f4 inset !important;
}