.border{
    border-radius: 25px;
}
.visible {
    /* display: flex; */
    top: 0;
    transition: top 0.4s ease-out;
  }
  
.hidden {
    /* display: none; */
    top: -150px;
    transition: top 0.4s ease-out;
  }
  #stickyheader{
    top: 0;
    position: fixed;
  }
 