.mainBarPrivacy425 {
  display: none;
}
@media only screen and (max-width: 600px) {
  .sideNavBarPrivacy {
    display: none;
  }
  .mainBarPrivacy425 {
    display: block;
  }
}
