.profile_img_p{
    width:100px;
    height:100px;
    margin-right: 18px;
}
.profile_img{
    width:75px;
    height:75px;
}
@media only screen and (max-width: 600px) {
    .profile_img_p{
        width:60px;
        height:60px;
        margin-right: 8px;
    }
    .profile_img{
        width:50px;
        height:50px;
    }
}