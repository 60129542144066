.mealsPlanImg {
  width: 100%;
  width: 290px;
  height: 180px;
  border-radius: 20px;
}
.socialIcon:hover{
  color:#E0F2F1
  ;
  cursor: pointer;
}
/* .qutoes_list{
display: flex;
text-align: center;
flex-direction: row;
flex-wrap: wrap;
} */
.insurance_image{
  width:300px
}
@media only screen and (max-width: 1024px) {
  .mealsPlanImg {
    width: 195px;
    height: 135px;
    border-radius: 15px;
  }
}
@media only screen and (max-width: 600px) {
    .mealsPlanImg {
      width: 195px;
      height: 98px;
      border-radius: 15px;
    }
    .qutoes_list{
      display: flex;
      text-align: center;
      flex-direction: row;
      flex-wrap: wrap;
      font-size: 2.2rem;
      justify-content: center;
      text-transform: uppercase;
      font-family: 'Roboto Condensed';
      font-weight: bold;
      }
      .qutoes_list >li{
        margin-left: 10px;
      }
      .insurance_image{
        width:100%
      }
  }

  .bullets li {
    list-style-type: '\2714\0020';
    padding-inline-start: 1ch;
  }
  